// React Core
import React, { useState } from 'react';
// Material-UI and other Libraries
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
// MUI Icons and Font Awesome
import PhoneIcon from '@material-ui/icons/Phone';
import TextIcon from '@material-ui/icons/Message';
import InfoIcon from '@material-ui/icons/InfoOutlined';
// Adapify
import gtag from '../Utilities/Gtag';
import TermsOfServicesDialog from './TermsofServicesDialog';
import PrivacyPolicyDialog from './PrivacyPolicyDialog';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  phoneIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
    marginTop: 4,
    marginRight: 4,
    marginLeft: 4,
  },
  textIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
    marginTop: 4,
  },
  infoIcon: {
    height: 14,
    marginBottom: 4,
    cursor: 'pointer',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const termsDialog = TermsOfServicesDialog('Terms and Conditions');
  const policyDialog = PrivacyPolicyDialog('Privacy Policy');

  const handleReturnDialogOpen = () => {
    setOpen(true);
  };

  const handleInfoDialogOpen = () => {
    setInfoOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  return (
    <>
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justifyContent="space-evenly">
          <Grid item xs={12} sm={6} key="Logo">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              <a href="/">
                <img
                  xs={12}
                  sm={6}
                  style={{
                    maxWidth: 250,
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto',
                  }}
                  src={`${process.env.PUBLIC_URL}/logo-wide.webp`}
                  height="55"
                  width="250"
                  alt="logo"
                />
              </a>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} key="company">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Company
            </Typography>
            <ul>
              <li key="phone">
                <Typography variant="button" color="textSecondary" gutterBottom>
                  <TextIcon className={classes.textIcon} />
                  <PhoneIcon className={classes.phoneIcon} />
                  844-797-6454
                  <InfoIcon
                    className={classes.infoIcon}
                    onClick={() => { handleInfoDialogOpen(); }}
                  />
                </Typography>
              </li>
              <li key="contact">
                <Link
                  href="/contact"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Footer - Contact Link Clicked',
                      event_label: 'Footer - Contact Link Clicked',
                      value: 'Footer - Contact Link Clicked',
                    });
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={3} key="services">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Services
            </Typography>
            <ul>
              <li key="partner">
                <Link
                  href="https://partner.rxsoil.com"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Footer - Partner Link Clicked',
                      event_label: 'Footer - Partner Link Clicked',
                      value: 'Footer - Partner Link Clicked',
                    });
                  }}
                >
                  Partners
                </Link>
              </li>
              <li key="support">
                <Link
                  href="/support"
                  variant="subtitle1"
                  color="textSecondary"
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Footer - Support Link Clicked',
                      event_label: 'Footer - Support Link Clicked',
                      value: 'Footer - Support Link Clicked',
                    });
                  }}
                >
                  Support
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
        <br />
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {termsDialog}
            &nbsp;&nbsp;&nbsp;
            {policyDialog}
            &nbsp;&nbsp;
            <Button
              color="primary"
              onClick={() => {
                handleReturnDialogOpen();
                gtag('event', 'click', {
                  event_category: 'Return Refund Dialog Clicked',
                  event_label: 'Return Refund Dialog Clicked',
                  value: 'Return Refund Dialog Clicked',
                });
              }}
            >
              Refund &amp; Return Policy
            </Button>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">Refund and Return Policy</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  If your kit is ever lost in the mail we will send you a replacement free of
                  charge. If you have an unused &amp; undamaged kit, you may return it to us after
                  contacting support for a full refund of your purchase price minus a $5 restocking
                  fee. You must request a refund within one year of your purchase for your return
                  to be accepted. If you would like to use this policy, or if you are unsatisfied
                  for any reason, please contact us using any of the options available at:
                  <br />
                  <Link href="/support">https://rxsoil.com/support</Link>
                  <br />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullScreen={fullScreen}
              open={infoOpen}
              onClose={handleInfoClose}
              aria-labelledby="responsive-dialog-title2"
            >
              <DialogTitle id="responsive-dialog-title2">Text Message Disclaimer</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  By calling or texting this number (844-797-6454),
                  customers agree to receive text messages. If they
                  no longer wish to receive text messages, they may
                  opt out at any time by replying &#34;STOP&#34;.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleInfoClose} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Typography>
          <br />
          <Typography variant="body2" color="textSecondary" align="center">
            Testing available nationwide
            <br />
            Headquartered in Roanoke, VA
            <br />
            Rx Soil kits and software assembled right here in the USA
            <br />
            {'© 2014-'}
            {`${new Date().getFullYear()} `}
            <Link
              color="primary"
              href="https://adapify.com/"
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Footer - Precription Soil Analysis Link Clicked',
                  event_label: 'Footer - Precription Soil Analysis Link Clicked',
                  value: 'Footer - Precription Soil Analysis Link Clicked',
                });
              }}
            >
              Adapify Inc.
            </Link>
            {' All Rights Reserved.'}
          </Typography>
        </Box>
      </Container>
      {/* End footer */}
    </>
  );
};

export default Footer;
