// React Redux Core
import React from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '0px 0px',
    margin: '0px',
  },
  container: {
    maxWidth: 400,
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  listStyling: {
    listStyleType: 'disc',
    textAlign: 'center',
    listStylePosition: 'inside',
    marginBottom: '30px',
    '& li': {
      marginBottom: '10px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const AnalyteDetailsDialog = ({ analyteClass }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <InfoOutlinedIcon
        className={classes.buyButton}
        variant="contained"
        color="primary"
        tabIndex="0"
        onClick={handleClickOpen}
        onKeyDown={(event) => {
          // For keyboard enter events
          if (event.keyCode === 13 || event.keyCode === 32) {
            handleClickOpen();
          }
        }}
      />

      <Dialog open={open} onClose={handleClose} scroll="paper" className={classes.dialog} hideBackdrop>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {analyteClass === 'nutrient' && (
            <div className={classes.container}>
              <Typography variant="h6">Nutrient Analysis Test</Typography>
              <hr />
              <br />
              <ul className={classes.listStyling}>
                <li>
                  Soil pH
                </li>
                <li>
                  Organic Matter
                </li>
                <li>
                  CEC (Nutrient Retention Capacity)
                </li>
                <li>
                  Phosphorus
                </li>
                <li>
                  Potassium
                </li>
                <li>
                  Calcium
                </li>
                <li>
                  Magnesium
                </li>
                <li>
                  Sulfur
                </li>
                <li>
                  Zinc
                </li>
                <li>
                  Manganese
                </li>
                <li>
                  Iron
                </li>
                <li>
                  Copper
                </li>
                <li>
                  Boron
                </li>
                <li>
                  Detailed Seasonal Recommendations
                </li>
              </ul>
            </div>
          )}
          {analyteClass === 'metal' && (
            <div className={classes.container}>
              <Typography variant="h6">Heavy Metals Package</Typography>
              <hr />
              <i>
                Includes part-per-million soil analysis, comparison to
                natural averages, and links to agency resources for:
              </i>
              <br />
              <br />
              <ul className={classes.listStyling}>
                <li>
                  Arsenic
                </li>
                <li>
                  Barium
                </li>
                <li>
                  Cadmium
                </li>
                <li>
                  Chromium
                </li>
                <li>
                  Lead
                </li>
                <li>
                  Mercury
                </li>
                <li>
                  Selenium
                </li>
                <li>
                  Silver
                </li>
              </ul>
            </div>
          )}
          {analyteClass === 'herbicide' && (
            <div className={classes.container}>
              <Typography variant="h6">Herbicide Package</Typography>
              <hr />
              <i>
                Shows if any of the following active ingredient herbicides are
                detectable, and if so, at what measured part-per-million:
              </i>
              <br />
              <br />
              <ul className={classes.listStyling}>
                <li>
                  2,4-D
                </li>
                <li>
                  Dicamba
                </li>
                <li>
                  Picloram
                </li>
                <li>
                  2,4-DB
                </li>
                <li>
                  Dichlorprop
                </li>
                <li>
                  Silvex
                </li>
                <li>
                  2,4,5-T
                </li>
                <li>
                  Bromoxynil
                </li>
                <li>
                  MCPP
                </li>
                <li>
                  MCPA
                </li>
                <li>
                  Dinoseb
                </li>
                <li>
                  Triclopyr
                </li>
              </ul>
            </div>
          )}
          {analyteClass === 'chlorinated' && (
            <div className={classes.container}>
              <Typography variant="h6">Nutrient Analysis Test:</Typography>
              <hr />
              <i>
                Shows if any of the following active ingredient insecticides are
                detectable, and if so, at what measured part-per-million:
              </i>
              <br />
              <br />
              <ul className={classes.listStyling}>
                <li>
                  DDT (4,4-DDT)
                </li>
                <li>
                  Aldrin
                </li>
                <li>
                  4,4-DDE
                </li>
                <li>
                  Alpha-BHC
                </li>
                <li>
                  Beta-BHC
                </li>
                <li>
                  Gamma-BHC
                </li>
                <li>
                  Delta-BHC
                </li>
                <li>
                  Dieldrin

                </li>
                <li>
                  Endrin
                </li>
                <li>
                  Heptachlor
                </li>
                <li>
                  Alpha-Chlordane
                </li>
                <li>
                  Gamma Chlordane
                </li>
                <li>
                  Endrin Aldehyde
                </li>
                <li>
                  Endrin Ketone
                </li>
                <li>
                  Endosulfan I
                </li>
                <li>
                  Endosulfan II
                </li>
                <li>
                  Endosulfan Sulfate
                </li>
                <li>
                  Methoxychlor
                </li>
                <li>
                  Heptachlor Epoxide
                </li>
                <li>
                  4,4-DDD
                </li>
              </ul>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AnalyteDetailsDialog;
