// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Paper from '@material-ui/core/Paper';
// Icons
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';
import AnalyteDetailsDialog from './AnalyteDetailsDialog';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0, 6),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/packageDealBanner.webp)`,
    backgroundPositionX: '65%',
    backgroundSize: 'cover',
    height: 575,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 30px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 30,
  },
  textHeader: {
    color: '#444',
    fontWeight: 600,
    fontSize: 26,
    textAlign: 'left',
  },
  textHeaderBottom: {
    color: '#444',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'left',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 16,
      fontWeight: 600,
    },
  },
  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  boxButton: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  emailButton: {
    height: 55,
    marginLeft: 10,
    [theme.breakpoints.down(651)]: {
      marginTop: 15,
    },
  },
  list: {
    justifyContent: 'center',
  },
  listItem: {
    textAlign: 'center',
    display: 'block',
    '& .MuiListItemText-root': {
      display: 'inline-flex',
      flex: 'auto',
    },
    '& .MuiListItemIcon-root': {
      display: 'inline-flex',
      justifyContent: 'center',
      '& svg': {
        top: 5,
        position: 'relative',
      },
    },
  },
  priceListStyling: {
    listStyleType: 'disc',
    textAlign: 'center',
    listStylePosition: 'inside',
    marginBottom: '30px',
    '& li': {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  main: {
    marginTop: 40,
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  mainDescription: {
    display: 'flex',
    justifyContent: 'center',
  },
  mainDescriptionText: {
    width: '70%',
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  ctaWrapper: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  primaryCta: {
    [theme.breakpoints.up(870)]: {
      width: '49.99%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 400,
    maxWidth: 600,
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  productName: {
    fontWeight: 600,
  },
  productPrice: {
    fontWeight: 600,
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  diagramImg: {
    width: '100%',
    maxWidth: 500,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  sampleReportImg: {
    width: '100%',
    maxWidth: 700,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  completePropertiesList: {
    listStyle: 'disc',
    listStylePosition: 'inside',
  },
  offeringsTable: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '1000px',
  },
  offeringsTableRow: {
    borderTop: '1px solid lightgray',
  },
  priceColumn: {
    margin: '0',
    padding: '0',
  },
  priceCards: {
    width: '100%',
    margin: '0 auto',
  },
  starCards: {
    display: 'flex',
    width: '100%',
    maxWidth: 980,
    justifyContent: 'center',
    padding: 0,
    margin: '0 auto',
    [theme.breakpoints.down(800)]: {
      maxWidth: 300,
      display: 'block',
      margin: '0 auto',
    },
  },
  starCard: {
    display: 'block',
    padding: '30px 10px',
    margin: 20,
    [theme.breakpoints.up(800)]: {
      width: '33%',
    },
  },
  priceCardPrice: {
    fontWeight: '600',
    fontSize: '24px',
    margin: '20px 20px',
  },
  priceCardDesc: {
    fontWeight: '450',
    fontSize: '14px',
    padding: '0px 0px',
  },
}));

const Packages = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Package Deals',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/packages`,
    page_path: '/packages',
  });

  if (window.localStorage && window.localStorage.getItem('source') === null && typeof URLSearchParams !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    let sourceToken = query.get('source');
    if (sourceToken === null) {
      sourceToken = 'direct';
    }
    window.localStorage.setItem('source', sourceToken);
  }

  const handleBuyHealthPackage = (which) => {
    let checkoutId;

    if (which === 'silver') {
      checkoutId = '263-1';
    } else if (which === 'gold') {
      checkoutId = '264-1';
    } else if (which === 'platinum') {
      checkoutId = '265-1';
    } else {
      checkoutId = '263-1'; // Defaulting to lowest
    }
    gtag('event', 'conversion', {
      event_category: `Conversion - Buy ${which} Button Clicked`,
      event_label: `Conversion - Buy ${which} Button Clicked`,
      value: `Conversion - Buy ${which} Button Clicked`,
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 229.95,
      items: [{
        id: 'RX006',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/${checkoutId}?source=${window.localStorage.getItem('source')}`);
  };

  return (
    <>
      <CssBaseline />
      <Header current="Package Deals" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
              Packages Perfect For:
            </Typography>
            <List dense={false}>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Backyard Gardeners"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Home Purchasers"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Urban Farmers"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Landscape Designers"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Sustainable Farming Advocates"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon className={classes.checkIcon} icon={faCheck} />
                </ListItemIcon>
                <ListItemText
                  className={classes.textBody}
                  primary="Community Gardens"
                />
              </ListItem>
            </List>
          </Box>
        </Container>
      </div>

      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          Bundle Testing Services, Save Money
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            Bundle soil nutrient and contaminant testing together for the most comprehensive
            reports at the best price.
          </Typography>
        </div>
      </div>

      <Container className={classes.main} maxWidth="lg">
        <div className={classes.priceCards}>
          <div className={classes.starCards}>
            <Paper className={classes.starCard} elevation={3}>
              <center>
                <img alt="Silver Package" width="240px" src={`${process.env.PUBLIC_URL}/images/silver.webp`} />
                <Typography className={classes.priceCardPrice} variant="h4" align="center">$239.95</Typography>
                <Typography className={classes.priceCardDesc} variant="subtitle" align="center" color="textPrimary">
                  Combine nutrient level testing with our most popular
                  heavy metals screen for an overall lab report on the
                  capabilities and health of your soil.
                  <br />
                  <br />
                  <ul className={classes.priceListStyling}>
                    <li>
                      - Test 13 nutrient levels
                      <AnalyteDetailsDialog analyteClass="nutrient" />
                    </li>
                    <li>
                      - Test 8 heavy metals levels
                      <AnalyteDetailsDialog analyteClass="metal" />
                    </li>
                    <li>
                      - Includes sampling materials, shipping, analysis, reports,
                      recommendations, & support
                    </li>
                  </ul>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyHealthPackage('silver')}
                  >
                    BUY NOW
                  </Button>
                </Typography>
              </center>
            </Paper>
            <Paper className={classes.starCard} elevation={3}>
              <center>
                <img alt="Gold Package" width="240px" src={`${process.env.PUBLIC_URL}/images/gold.webp`} />
                <Typography className={classes.priceCardPrice} variant="h4" align="center">$599.95</Typography>
                <Typography className={classes.priceCardDesc} variant="subtitle" align="center" color="textPrimary">
                  Adds herbicide and pesticide testing to the nutrient
                  and heavy metals package, allowing for a comprehensive
                  lab check of common chemical contaminants.
                  <br />
                  <br />
                  <ul className={classes.priceListStyling}>
                    <li>
                      - Test 13 nutrient levels
                      <AnalyteDetailsDialog analyteClass="nutrient" />
                    </li>
                    <li>
                      - Test 8 heavy metals levels
                      <AnalyteDetailsDialog analyteClass="metal" />
                    </li>
                    <li>
                      - Test 12 common herbicides
                      <AnalyteDetailsDialog analyteClass="herbicide" />
                    </li>
                    <li>
                      - Test 20 common pesticides
                      <AnalyteDetailsDialog analyteClass="chlorinated" />
                    </li>
                    <li>
                      - Includes sampling materials, shipping, analysis, reports,
                      recommendations, & support
                    </li>
                  </ul>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyHealthPackage('gold')}
                  >
                    BUY NOW
                  </Button>
                </Typography>
              </center>
            </Paper>
            <Paper className={classes.starCard} elevation={3}>
              <center>
                <img alt="Platinum Package" width="240px" src={`${process.env.PUBLIC_URL}/images/plat.webp`} />
                <Typography className={classes.priceCardPrice} variant="h4" align="center">$879.95</Typography>
                <Typography className={classes.priceCardDesc} variant="subtitle" align="center" color="textPrimary">
                  Adds Glyphosate (Roundup®) testing to the packages above, allowing
                  for the most complete look at the soil and chemical
                  and industrial contaminants.
                  <br />
                  <br />
                  <ul className={classes.priceListStyling}>
                    <li>
                      - Test 13 nutrient levels
                      <AnalyteDetailsDialog analyteClass="nutrient" />
                    </li>
                    <li>
                      - Test 8 heavy metals levels
                      <AnalyteDetailsDialog analyteClass="metal" />
                    </li>
                    <li>
                      - Test 12 common herbicides
                      <AnalyteDetailsDialog analyteClass="herbicide" />
                    </li>
                    <li>
                      - Test 20 common pesticides
                      <AnalyteDetailsDialog analyteClass="chlorinated" />
                    </li>
                    <li>
                      - Test for glyphosate
                    </li>
                    <li>
                      - Includes sampling materials, shipping, analysis, reports,
                      recommendations, & support
                    </li>
                  </ul>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyHealthPackage('platinum')}
                  >
                    BUY NOW
                  </Button>
                </Typography>
              </center>
            </Paper>
          </div>
        </div>
        <br />
      </Container>

      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          With an Organization Interested in Bulk Rates?
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            We are happy to help with customized quotes for bulk pricing on any
            of our test offerings. Call or contact us today for custom quotes on
            multiple or customized test packages.
          </Typography>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default Packages;
