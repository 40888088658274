// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
// Icons
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/compostBanner.webp)`,
    backgroundPositionY: '100%',
    backgroundPositionX: '20%',
    backgroundSize: 'cover',
    height: 600,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'right',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 80,
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  mainDescription: {
    display: 'flex',
    justifyContent: 'center',
  },
  mainDescriptionText: {
    width: '70%',
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  secondCta: {
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  productName: {
    fontWeight: 600,
  },
  productPrice: {
    fontWeight: 600,
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  instructionsImg: {
    width: '100%',
    maxWidth: 840,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  bioTestPropertiesList: {
    listStyle: 'disc',
    listStylePosition: 'inside',
  },
  innerLink: {
    color: '#3737a9',
    fontSize: 20,
    fontWeight: 600,
    textDecoration: 'underline',
  },
  mainAlert: {
    '& .MuiAlertTitle-root': {
      fontWeight: 300,
      fontSize: 18,
      color: '#fff',
    },
    '& .MuiSvgIcon-root': {
      fill: '#fff',
      width: 30,
      height: 30,
    },
    backgroundColor: '#7ab0de',
    width: '80%',
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: 15,
    fontSize: 18,
    fontWeight: 400,
  },
}));

const Compost = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Compost Testing',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/compost`,
    page_path: '/compost',
  });

  if (window.localStorage && window.localStorage.getItem('source') === null && typeof URLSearchParams !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    let sourceToken = query.get('source');
    if (sourceToken === null) {
      sourceToken = 'direct';
    }
    window.localStorage.setItem('source', sourceToken);
  }

  const handleBuy = (which) => {
    gtag('event', 'conversion', {
      event_category: `Conversion - Buy ${which} Button Clicked`,
      event_label: `Conversion - Buy ${which} Button Clicked`,
      value: `Conversion - Buy ${which} Button Clicked`,
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 89.95,
      items: [{
        id: 'RX002',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/257-1?source=${window.localStorage.getItem('source')}`);
  };

  return (
    <>
      <CssBaseline />
      <Header current="compost" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              Lab Analysis of Your Compost, Made Easy
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              Whether you are comparing your compost from one season to the next,
              or sharing it with others, a detailed lab compost report allows you to
              know precisely where your levels are and exactly what nutrients this compost will add
              to your soil.
            </Typography>
          </Box>
        </Container>
      </div>

      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item style={{ margin: '0 auto' }}>
                <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Rx Soil Compost Analysis
                </Typography>
                <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                  $89.95
                </Typography>
                <div className={classes.checkWrapper}>
                  Do you agree to our terms and conditions?
                  <Checkbox className={classes.confirmChecked} />
                </div>
                <Button
                  className={classes.buyButton}
                  variant="contained"
                  color="primary"
                  onClick={() => handleBuy('Top')}
                >
                  BUY NOW
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={(classes.section)}>
            <img
              alt="Soil analysis kit"
              height="286"
              width="420"
              className={classes.boxImg}
              src={`${process.env.PUBLIC_URL}/images/b2cCompost.webp`}
              loading="lazy"
            />
          </div>
        </div>
      </Container>

      <Container className={classes.blurbs} maxWidth="lg">
        <div className={classes.blurb}>
          <img
            alt="Laboratory glass in front with computer report in back"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/soilLab.webp`}
            title="Knowledge"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Full Compost Analysis Report
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Just like any other soil amendment, your compost
            also has ratios of important macro and micro nutrients. With a simple mail-in lab test,
            you can identify these, and know precisely what you are adjusting in your soil.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Laboratory with scientists"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/labIcon.webp`}
            title="Compost"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Know the Strengths of Your Compost
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Your soil amendment report will details 18 properties of your sample, letting
            your track the progress of your batch or continuous compost over time. Contents
            are reported to you both as percentages and weights.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Test kit box moving quickly on back of truck"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/fastShipping.webp`}
            title="Shipping"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Fast Free Shipping
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Two-way shipping costs are included - to get the collection materials to you,
            and to mail your soil sample to the lab. Get your compost report right
            from the comfort of your home in about one week.
          </Typography>
        </div>
      </Container>

      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          A Compost Testing Report With Convenience and Ease
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            Like other Rx Soil testing services, your soil compost sample is processed quickly at a
            commercial-grade agronomics lab, and we typically have results returned to you about a
            week later. Your soil compost report will detail the following properties:
            <br />
            <br />
            <ul className={classes.bioTestPropertiesList}>
              <li>Moisture Percentage</li>
              <li>Solid Percentage</li>
              <li>Total Nitrogen</li>
              <li>Phosphorus</li>
              <li>Potassium</li>
              <li>Sulfur</li>
              <li>Magnesium</li>
              <li>Calcium</li>
              <li>Sodium</li>
              <li>Iron</li>
              <li>Aluminum</li>
              <li>Manganese</li>
              <li>Copper</li>
              <li>Zinc</li>
              <li>Boron</li>
              <li>Ammonium</li>
              <li>
                P
                <sub>2</sub>
                O
                <sub>5</sub>
                {' '}
                Equivalent
              </li>
              <li>
                K
                <sub>2</sub>
                O Equivalent
              </li>
            </ul>
            <br />
            As with all of our testing, you have the option of using our site to assign your
            results to a growing area. This allows you to keep track of your compost data
            over different batches and different seasons.
          </Typography>
        </div>
        <Alert className={classes.mainAlert} severity="info">
          Note: This page contains information about the nutrients and other growth properties of
          your compost. If you are interested in testing for heavy metals in your compost,
          that service can be found on our
          <span> </span>
          <Link
            href="/metals"
            variant="subtitle1"
            color="textSecondary"
            className={classes.innerLink}
          >
            Heavy Metals Page
          </Link>
          .
        </Alert>
        <br />
        <br />
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            Here is an example of the data you will find in your compost nutrient report:
          </Typography>
        </div>
        <br />
        <br />
        <img
          alt="Compost data breakdown by moisture and n, p, and k"
          height="1280"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/compostSample1.webp`}
          loading="lazy"
        />
        <img
          alt="Compost data breakdown by individual nutrients and parts per million"
          height="1280"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/compostSample2.webp`}
          loading="lazy"
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={classes.bottomCTA}>
        <Typography variant="h5" align="center" gutterBottom>
          Ready to get started? Purchase our compost soil analysis now or contact us with
          any questions.
        </Typography>
      </div>
      <br />
      <br />
      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item style={{ margin: '0 auto' }}>
                <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Rx Soil Compost Analysis
                </Typography>
                <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                  $89.95
                </Typography>
                <div className={classes.checkWrapper}>
                  Do you agree to our terms and conditions?
                  <Checkbox className={classes.confirmChecked} />
                </div>
                <Button
                  className={classes.buyButton}
                  variant="contained"
                  color="primary"
                  onClick={() => handleBuy('Bottom')}
                >
                  BUY NOW
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={(classes.section)}>
            <img
              alt="Soil analysis kit"
              height="286"
              width="420"
              className={classes.boxImg}
              src={`${process.env.PUBLIC_URL}/images/b2cCompost.webp`}
              loading="lazy"
            />
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default Compost;
