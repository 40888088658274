// React Core
import React from 'react';
// Material-UI and other Libraries
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
// Icons
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/scienceBanner.webp)`,
    backgroundSize: 'cover',
    height: 600,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'right',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  heroBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 40,
  },
  textHeader: {
    color: '#444',
    fontWeight: 700,
    textAlign: 'center',
  },
  textBody: {
    '& .MuiListItemText-primary': {
      color: '#444',
      fontSize: 20,
      fontWeight: 600,
    },
  },
  main: {
    marginTop: 80,
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  mainDescription: {
    display: 'flex',
    justifyContent: 'center',
  },
  mainDescriptionText: {
    width: '70%',
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 70,
    marginBottom: 70,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  ctaWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: 70,
    marginBottom: 70,
    [theme.breakpoints.down(1107)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  crossPrice: {
    textDecoration: 'line-through',
    color: '#cf7474',
  },
  primaryCta: {
    [theme.breakpoints.up(870)]: {
      margin: 10,
    },
    margin: '20px auto',
    maxWidth: 600,
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  productName: {
    fontWeight: 600,
  },
  productPrice: {
    fontWeight: 600,
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  instructionsImg: {
    width: '100%',
    maxWidth: 840,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  bioTestPropertiesList: {
    listStyle: 'disc',
    listStylePosition: 'inside',
  },
}));

const Texture = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Texture Testing',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/texture`,
    page_path: '/texture',
  });

  if (window.localStorage && window.localStorage.getItem('source') === null && typeof URLSearchParams !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    let sourceToken = query.get('source');
    if (sourceToken === null) {
      sourceToken = 'direct';
    }
    window.localStorage.setItem('source', sourceToken);
  }

  const handleBuyTexture = () => {
    gtag('event', 'conversion', {
      event_category: 'Conversion - Buy Texture Button Clicked',
      event_label: 'Conversion - Buy Texture Button Clicked',
      value: 'Conversion - Buy Texture Button Clicked',
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 49.95,
      items: [{
        id: 'RX003',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/255-1?source=${window.localStorage.getItem('source')}`);
  };

  const handleBuyPackage = () => {
    gtag('event', 'conversion', {
      event_category: 'Conversion - Buy Texture Package Button Clicked',
      event_label: 'Conversion - Buy Texture Package Button Clicked',
      value: 'Conversion - Buy Texture Package Button Clicked',
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 84.95,
      items: [{
        id: 'RX003',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/236-1_256-1?source=${window.localStorage.getItem('source')}`);
  };

  return (
    <>
      <CssBaseline />
      <Header current="texture" />

      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Container maxWidth="sm">
          <Box className={classes.heroBox}>
            <Typography className={classes.textHeader} variant="h5" align="center" color="textSecondary" paragraph>
              Discover the Composition of Your Soil
            </Typography>
            <Typography className={classes.textBody} variant="h6" align="center" color="textSecondary" paragraph>
              Sand, Silt, Clay, Loam. What exactly is in your soil? With a
              laboratory texture analysis test kit, Rx Soil makes it easy know the
              exact percentages of your soil&apos;s components. Use this information
              in combination with a yearly nutrient analysis to make the best decisions
              about your growing areas.
            </Typography>
          </Box>
        </Container>
      </div>

      <Container className={classes.blurbs} maxWidth="lg">
        <div className={classes.blurb}>
          <img
            alt="Laboratory with scientists"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/labIcon.webp`}
            title="Microbiome"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Know Your Soil Type
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            There are key differences in the way you work with different soil types,
            and how to make plants and grasses thrive in them. Sandy loam? Silty clay?
            Let Rx Soil take the guesswork out and provide the real answer.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="System with gears and computer"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/systemIcon.webp`}
            title="Knowledge"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            One and Done
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Unlike a soil nutrient analysis, texture testing really only needs to happen once.
            Your soil nutrient levels are constantly changing, but your soil texture will
            remain mostly consistant until you bring new soil in.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Test kit box moving quickly on back of truck"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/fastShipping.webp`}
            title="Shipping"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Fast Free Shipping
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Two-way shipping costs are included - to get the kit to you and to mail your
            soil sample to the lab. Get your soil texture analyzed right from the comfort of
            your home in about a week.
          </Typography>
        </div>
      </Container>

      <Container className={classes.ctaWrapper} maxWidth="lg">
        <div className={classes.primaryCta}>
          <div className={classes.twoSections}>
            <div className={classes.section}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item style={{ margin: '0 auto' }}>
                  <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                    Texture Test
                  </Typography>
                  <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                    $49.95
                  </Typography>
                  <div className={classes.checkWrapper}>
                    Do you agree to our terms and conditions?
                    <Checkbox className={classes.confirmChecked} />
                  </div>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyTexture()}
                  >
                    BUY NOW
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className={(classes.section)}>
              <img
                alt="Texture analysis kit"
                height="275"
                width="275"
                src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className={classes.primaryCta}>
          <div className={classes.twoSections}>
            <div className={classes.section}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item style={{ margin: '0 auto' }}>
                  <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                    Texture Test + Nutrient Analysis
                  </Typography>
                  <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                    $84.95
                    <span> </span>
                    <span className={classes.crossPrice}>$89.95</span>
                  </Typography>
                  <div className={classes.checkWrapper}>
                    Do you agree to our terms and conditions?
                    <Checkbox className={classes.confirmChecked} />
                  </div>
                  <Button
                    className={classes.buyButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleBuyPackage()}
                  >
                    BUY NOW
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className={(classes.section)}>
              <img
                alt="Texture analysis kit"
                height="275"
                width="275"
                src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </Container>

      <div className={classes.main}>
        <Typography variant="h6" align="center" gutterBottom>
          What is Included in a Texture Analysis?
        </Typography>
        <div className={classes.mainDescription}>
          <Typography className={classes.mainDescriptionText} variant="subtitle2" component="p" align="center" paragraph>
            This report focuses on the components in your soil.
            Your report will contain a percentage breakdown of
            the sand, slit, and clay found in your sample, as well
            as the precise classification of your soil type.
            <br />
            <br />
            Like all Rx Soil tests, the price of the kit includes the
            sample test kit, mailer box, sample collection bag,
            instructions, lab fee, and report.
            <br />
            <br />
            A partial example of the data presented in your report:
          </Typography>
        </div>
        <br />
        <br />
        <img
          alt="Texture analysis showing clay soil"
          height="1280"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/textureReport.webp`}
          loading="lazy"
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={classes.bottomCTA}>
        <Typography variant="h5" align="center" gutterBottom>
          Ready to get started? Purchase our texture analysis kit now, or contact us with
          any questions.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Texture;
