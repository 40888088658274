// React Redux Core
import React from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '10px 40px',
  },
  link: {
    color: theme.palette.primary.main,
    marginTop: 30,
    '&:hover': {
      cursor: 'pointer',
      color: '#43a047',
    },
    '&:focus': {
      outline: 'none',
      color: '#43a047',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.primary.gray,
  },
  okay: {
    float: 'right',
    marginButton: 5,
  },
}));

const PrivacyPolicyDialog = (linkText) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span
        className={classes.link}
        tabIndex="0"
        onClick={handleClickOpen}
        onKeyDown={(event) => {
          // For keyboard enter events
          if (event.keyCode === 13 || event.keyCode === 32) {
            handleClickOpen();
          }
        }}
        role="button"
      >
        {linkText}
      </span>
      <Dialog open={open} onClose={handleClose} scroll="paper" hideBackdrop>
        <DialogTitle>
          Privacy Policy
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" color="primary" paragraph>
            Who we are
          </Typography>
          <Typography paragraph>
            Our website address is: https://rxsoil.com
            We&apos;re a software company helping individual and businesses
            improve the way people use their soil.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            What personal data we collect and why
          </Typography>
          <Typography paragraph>
            Media:
            We may retain any media you provide related to your
            use of the RxSoil Platform for up to six months. We
            are not responsible for maintaining media on the
            platform and do not claim any rights or privileges
            to use it beyond the scope of the RxSoil Platform and
            its services.
          </Typography>
          <Typography paragraph>
            Contact forms:
            No contact information that you enter into any site
            or app that we control, will ever be sold to 3rd
            parties or used to spam you and will only every be
            used in regards to the site or application that you
            entered your information for.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            Cookies
          </Typography>
          <Typography paragraph>
            If you leave feedback or submit any form on our site
            you may opt-in to saving that information provided in
            cookies. These are for your convenience so that you do
            not have to fill in your details again when submitting a
            form that requires the same information. These cookies
            will last for up to a year or until cleared.
          </Typography>
          <Typography paragraph>
            If you have an account and you log in to this site, we
            will set up several cookies to save your login
            information and your screen display choices. Login
            cookies last for seven days, and screen options are device
            specific. If you select “Remember Me”, your login will
            persist for 90 days. If you log out of your account, the
            login cookies will be removed.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            Embedded content
          </Typography>
          <Typography paragraph>
            Embedded content from other websites:
            Some places on the site and apps may include embedded
            content (e.g. youtube videos, images, articles, etc.).
            Embedded content from other websites behaves in the exact
            same way as if the visitor has visited the other website.
          </Typography>
          <Typography paragraph>
            These websites may collect data about you, use cookies,
            embed additional third-party tracking, and monitor your
            interaction with that embedded content, including tracking
            your interaction with the embedded content if you have an
            account and are logged in to that website.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            How long we retain your data
          </Typography>
          <Typography paragraph>
            We may retain any data you provide related to your
            use of the RxSoil Platform for up to six months. We
            are not responsible for maintaining media on the
            platform and do not claim any rights or priveledges
            to use it beyond the scope of the RxSoil Platform and
            its services.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            What rights you have over your data
          </Typography>
          <Typography paragraph>
            If you have an account on this site, or have provided
            any data, you can request to receive an exported file
            of the personal data we hold about you, including any
            data you have provided to us. You can also request that
            we erase any personal data we hold about you. This does
            not include any data we are obliged to keep for
            administrative, legal, or security purposes.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            How we protect your data
          </Typography>
          <Typography paragraph>
            RxSoil has implemented administrative and technical
            safeguards it believes are appropriate to protect the
            confidentiality, integrity and availability of your
            personal data and access credentials. However, given
            sufficient resources, a determined attacker could defeat
            those safeguards and may, as a result, gain access to the
            data we seek to protect.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            California privacy rights
          </Typography>
          <Typography paragraph>
            Residents of California have the right to:
            Know what personal information we collect about you and
            from where we collect it. Access a copy of the personal
            information we hold about you. Understand how we process
            your personal information and how it may be shared. Opt
            out of having your personal information shared with
            certain third parties. Request we delete your RxSoil
            account and personal information. To make such a request,
            please send an email to info@rxsoil.com.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            EU-U.S. Privacy Shield And Swiss-U.S. Privacy Shield
          </Typography>
          <Typography paragraph>
            RxSoil complies with the EU-US Privacy Shield Framework
            and the Swiss-US Privacy Shield Framework as set forth by
            the US Department of Commerce regarding the collection,
            use, and retention of personal information from European
            Union member countries (including Iceland, Liechtenstein,
            and Norway) and Switzerland transferred to the United
            States pursuant to Privacy Shield. RxSoil has certified
            that it adheres to the Privacy Shield Principles with
            respect to such data. If there is any conflict between the
            policies in this privacy policy and data subject rights
            under the Privacy Shield Principles, the Privacy Shield
            Principles shall govern. To learn more about the Privacy
            Shield program, and to view our certification page, please
            visit https://www.privacyshield.gov/.
          </Typography>
          <Typography paragraph>
            Pursuant to the Privacy Shield Frameworks, EU and Swiss
            individuals have the right to obtain our confirmation of
            whether we maintain personal information relating to you in
            the United States. Upon request, we will provide you with
            access to the personal information that we hold about you.
            You may also may correct, amend, or delete the personal
            information we hold about you. An individual who seeks
            access, or who seeks to correct, amend, or delete inaccurate
            data transferred to the United States under Privacy Shield,
            should direct their query to info@rxsoil.com. If
            requested to remove data, we will respond within a reasonable
            timeframe.
          </Typography>
          <Typography paragraph>
            We will provide an individual opt-out choice, or opt-in for
            sensitive data, before we share your data with third parties
            other than our agents. To request to limit the use and disclosure of
            your personal information, please submit a written request
            to info@rxsoil.com.
          </Typography>
          <Typography paragraph>
            RxSoil&apos;s accountability for personal data that it receives in
            the United States under the Privacy Shield and subsequently
            transfers to a third party is described in the Privacy Shield
            Principles. In particular, RxSoil remains responsible and
            liable under the Privacy Shield Principles if third-party
            agents that it engages to process the personal data on its
            behalf do so in a manner inconsistent with the Principles,
            unless RxSoil proves that it is not responsible for the event
            giving rise to the damage.
          </Typography>
          <Typography paragraph>
            With respect to personal data received or transferred pursuant
            to the Privacy Shield Frameworks, RxSoil is subject to the
            regulatory enforcement powers of the U.S. Federal Trade
            Commission. In certain situations, RxSoil may be required to
            disclose personal data in response to lawful requests by public
            authorities, including to meet national security or law
            enforcement requirements.
          </Typography>
          <Typography paragraph>
            In compliance with the EU-US and Swiss-US Privacy Shield
            Principles, RxSoil commits to resolve complaints about your
            privacy and our collection or use of your personal data.
            European Union or Swiss individuals with inquiries or
            complaints regarding this Privacy Statement should first
            <br />
            contact RxSoil Data Protection Officer at:
            <br />
            Data Protection Officer, RxSoil, Inc.
            <br />
            115 Eaglewood Way
            <br />
            Athens, GA, 30606
            <br />
            United States
            <br />
            Phone: +1-540-354-4488
            <br />
            E-mail: info@rxsoil.com
            <br />
          </Typography>
          <Typography paragraph>
            RxSoil has further committed to refer unresolved privacy
            complaints under the Privacy Shield Principles to an
            independent dispute resolution mechanism, the BBB EU PRIVACY
            SHIELD, operated by the Council of Better Business Bureaus.
            If you do not receive timely acknowledgement of your complaint,
            or if your complaint is not satisfactorily addressed, please
            visit https://www.bbb.org/EU-privacy-shield/for-eu-consumers
            for more information and to file a complaint. The services of
            BBB EU PRIVACY SHIELD are provided at no cost to you.
          </Typography>
          <Typography paragraph>
            If your complaint involves human resources data transferred to
            the United States from the EU and/or Switzerland in the context
            of the employment relationship, and RxSoil does not address it
            satisfactorily, RxSoil commits to cooperate with the panel
            established by the EU data protection authorities (DPA Panel)
            and/or the Swiss Federal Data Protection and Information
            Commissioner, as applicable and to comply with the advice given
            by the DPA panel and/or Commissioner, as applicable with regard
            to such human resources data. To pursue an unresolved human
            resources complaint, you should contact the state or national
            data protection or labor authority in the appropriate
            jurisdiction. Complaints related to human resources data should
            not be addressed to the BBB EU PRIVACY SHIELD.
          </Typography>
          <Typography paragraph>
            Contact details for the EU data protection authorities can be
            found at:
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
          </Typography>
          <Typography paragraph>
            If your Privacy Shield complaint cannot be resolved through the
            above channels, under certain conditions, you may invoke binding
            arbitration for some residual claims not resolved by other
            redress mechanisms. See Privacy Shield Annex 1 at
            https://www.privacyshield.gov/article?id=ANNEX-I-introduction.
          </Typography>
          <Typography paragraph>
            At all times RxSoil does not knowingly collect personal information
            from children in connection with the Service. If we discover that we
            have unknowingly collected personal information from these children,
            we will delete it. If you believe we have collected personal
            information from a child, please contact us at info@rxsoil.com.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            Do Not Track
          </Typography>
          <Typography paragraph>
            The Service is not designed to respond to “do not track” signals sent
            by some browsers.
          </Typography>
          <Typography variant="h6" color="primary" paragraph>
            Contact Us
          </Typography>
          <Typography>
            RxSoil headquarters are located within the USA at:
            <br />
            RxSoil Inc.
            <br />
            40 Summers Way
            <br />
            Roanoke, VA 24019
            <br />
            United States of America
            <br />
            Phone: +1-844-797-6454
            <br />
          </Typography>
          <Typography>
            <center>Last revised on 5th November, 2021</center>
          </Typography>
          <Button onClick={handleClose} color="primary" variant="contained" size="medium" className={classes.okay}>
            Okay
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrivacyPolicyDialog;
