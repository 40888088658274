// React Core
import React from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
// import Link from '@material-ui/core/Link';
// MUI Icons and Font Awesome
// Adapify
import gtag from '../Utilities/Gtag';
import Header from './Header';
import Footer from './Footer';
import NutrientPricingDialog from './NutrientPricingDialog';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    position: 'relative',
    padding: theme.spacing(8, 0, 6),
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/B2CBanner.webp)`,
    backgroundPositionY: '100%',
    backgroundPositionX: '100%',
    backgroundSize: 'cover',
    height: 420,
    '& .MuiContainer-root': {
      zIndex: 10,
      position: 'relative',
      float: 'left',
      margin: '30px 70px',
      [theme.breakpoints.down(900)]: {
        float: 'none',
        margin: '0 auto',
      },
    },
    [theme.breakpoints.down(900)]: {
      height: 'auto',
    },
  },
  heroOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 0,
  },
  heroBox: {
    display: 'table',
    position: 'relative',
    textAlign: 'left',
    height: '100%',
    margin: 'auto 40px',
    maxWidth: '70%',
    zIndex: 1,
    [theme.breakpoints.down(900)]: {
      maxWidth: '100%',
      '& h1': {
        textAlign: 'center',
      },
    },
    [theme.breakpoints.down(500)]: {
      margin: 'auto 20px',
    },
  },
  textHeader: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'left',
    color: '#fff',
    fontWeight: 600,
    fontSize: 48,
    [theme.breakpoints.down(500)]: {
      fontSize: 28,
    },
  },
  ctaForm: {
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      minWidth: 582,
    },
  },
  cta2Form: {
    padding: 16,
    marginTop: 80,
    textAlign: 'center',
    '& input': {
      width: 320,
    },
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
    },
    [theme.breakpoints.down(500)]: {
      '& input': {
        width: '100%',
      },
    },
    [theme.breakpoints.up(1050)]: {
      padding: 0,
      minWidth: 582,
    },
  },
  videoSection: {
    marginTop: 50,
  },
  videoWrapper: {
    marginTop: 60,
  },
  video: {
    borderRadius: 4,
    width: 560,
    height: 315,
    [theme.breakpoints.up(901)]: {
      width: 650,
      height: 365,
    },
    [theme.breakpoints.down(651)]: {
      width: 420,
      height: 234,
    },
    [theme.breakpoints.down(500)]: {
      width: 300,
      height: 167,
    },
  },
  secondCta: {
    marginTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down(900)]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  twoSections: {
    [theme.breakpoints.up(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    display: 'block',
    width: '100%',
  },
  section: {
    margin: '0 20px',
    width: '50%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: '40px 0',
      padding: 15,
      width: '100%',
    },
  },
  sectionIcon: {
    margin: '0 20px',
    width: '30%',
    [theme.breakpoints.down(900)]: {
      margin: '0 auto',
      '& img': {
        width: 75,
        display: 'block',
        height: 'auto',
      },
    },
    '& img': {
      maxWidth: 200,
    },
  },
  sectionIconMob: {
    display: 'none',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      display: 'block',
      width: '100%',
      '& img': {
        width: 75,
        display: 'block',
        height: 'auto',
      },
    },
    '& img': {
      display: 'block',
      margin: '0 auto',
      maxWidth: 200,
    },
  },
  sectionIconDesk: {
    display: 'block',
    margin: '0 20px',
    width: '30%',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    },
    '& img': {
      maxWidth: 200,
    },
  },
  sectionText: {
    margin: '0 20px',
    width: '70%',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  mainAlert: {
    '& .MuiAlertTitle-root': {
      fontWeight: 600,
      fontSize: 24,
      color: '#fff',
    },
    '& .MuiSvgIcon-root': {
      fill: '#fff',
      width: 30,
      height: 30,
    },
    backgroundColor: '#7ab0de',
    width: '80%',
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: 50,
    fontSize: 20,
    fontWeight: 500,
  },
  volumeAlert: {
    '& .MuiAlertTitle-root': {
      fontWeight: 600,
      fontSize: 24,
      color: '#fff',
    },
    '& .MuiSvgIcon-root': {
      fill: '#fff',
      width: 30,
      height: 30,
    },
    backgroundColor: '#fcba03',
    width: '80%',
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: 50,
    fontSize: 20,
    fontWeight: 500,
  },
  blurbs: {
    [theme.breakpoints.up(870)]: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    display: 'block',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  blurb: {
    [theme.breakpoints.up(870)]: {
      width: '33.3333%',
      margin: 10,
    },
    margin: '20px auto',
    minWidth: 200,
    maxWidth: 400,
    '& img': {
      display: 'block',
      margin: '24px auto',
      width: 120,
      height: 'auto',
    },
    '& h6': {
      fontWeight: '600',
    },
    '& p': {
      fontWeight: '450',
      fontSize: '18px',
      padding: '20px 20px',
    },
  },
  boxImg: {
    height: 'auto',
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
    [theme.breakpoints.up(501)]: {
      width: 420,
    },
  },
  productName: {
    fontWeight: 600,
    fontStyle: 'italic',
    display: 'inline-block',
    '& span': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down(840)]: {
      textAlign: 'center',
    },
  },
  productPrice: {
    fontWeight: 600,
    [theme.breakpoints.down(840)]: {
      textAlign: 'center',
    },
  },
  buyButtonWrapper: {
    [theme.breakpoints.down(840)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  buyButton: {
    fontWeight: 600,
  },
  checkWrapper: {
    zIndex: 100,
    position: 'absolute',
    right: '10000px',
    width: '0px',
    height: '0px',
  },
  main: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    marginTop: 70,
    marginBottom: 40,
    padding: 80,
    '& .MuiGrid-root.MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    [theme.breakpoints.down(510)]: {
      padding: 10,
      '& h4': {
        marginTop: 40,
        marginBottom: 40,
        fontSize: 20,
      },
    },
  },
  analysis: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  bottomCTA: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  mainSection: {
    height: 440,
    width: 'auto',
    margin: 40,
    padding: 20,
    [theme.breakpoints.down(900)]: {
      height: 'auto',
      justifyContent: 'center',
      margin: 0,
      padding: 15,
      width: '100%',
    },
  },
  instructionsImg: {
    width: '100%',
    maxWidth: 840,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  recommendationsImg: {
    width: '100%',
    maxWidth: 550,
    height: 'auto',
    margin: '0 auto',
    display: 'block',
  },
  bagImg: {
    width: 'auto',
    height: '100%',
    maxHeight: 400,
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(840)]: {
      maxWidth: 360,
      width: '100%',
      height: 'auto',
    },
  },
  shipImg: {
    width: 'auto',
    height: '100%',
    maxHeight: 340,
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down(840)]: {
      maxWidth: 480,
      width: '100%',
      height: 'auto',
    },
  },
  fabContainer: {
    zIndex: 1000,
    width: 350,
    height: 500,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  orderDisable: {
    color: 'white',
    backgroundColor: '#fa8072',
    fontSize: 16,
    padding: 10,
  },
  innerLink: {
    color: '#3737a9',
    fontSize: 20,
    fontWeight: 600,
    textDecoration: 'underline',
  },
}));

const Nutrients = () => {
  const classes = useStyles();
  gtag();
  gtag('event', 'page_view', {
    page_title: 'Nutrients',
    page_location: `${process.env.REACT_APP_ADAPIFY_SITE_URL}/Nutrients`,
    page_path: '/nutrients',
  });
  gtag('event', 'view_item', {
    send_to: 'AW-831484399',
    value: 49.95,
    items: [{
      id: 'RX001',
      google_business_vertical: 'retail',
    }],
  });

  if (window.localStorage && window.localStorage.getItem('source') === null && typeof URLSearchParams !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    let sourceToken = query.get('source');
    if (sourceToken === null) {
      sourceToken = 'direct';
    }
    window.localStorage.setItem('source', sourceToken);
  }

  // ***NOTE: comment out this function if temporarily disabling purchasing
  const handleBuy = (which, quantity) => {
    let productUrl = '236-1';
    if (quantity === 2) {
      productUrl = '258-1';
    } else if (quantity === 3) {
      productUrl = '259-1';
    } else if (quantity === 4) {
      productUrl = '260-1';
    } else if (quantity === 5) {
      productUrl = '261-1';
    }
    gtag('event', 'conversion', {
      event_category: `Conversion - Buy ${which} Button Clicked`,
      event_label: `Conversion - Buy ${which} Button Clicked`,
      value: `Conversion - Buy ${which} Button Clicked`,
    });
    gtag('event', 'add_to_cart', {
      send_to: 'AW-831484399',
      value: 49.95,
      items: [{
        id: 'RX001',
        google_business_vertical: 'retail',
      }],
    });
    window.location.replace(`https://app.rxsoil.com/checkout/${productUrl}?source=${window.localStorage.getItem('source')}`);
  };

  return (
    <>
      <CssBaseline />
      <Header current="nutrients" />
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <div className={classes.heroOverlay} />
        <Box className={classes.heroBox}>
          <Typography className={classes.textHeader} component="h1" variant="h1" align="center">
            Test your soil and improve the health of your lawn, garden, or landscape.
          </Typography>
        </Box>
      </div>
      {/* End hero unit */}

      <Alert className={classes.mainAlert} severity="info">
        <strong>Rx Soil Summer Sale Going On Now! </strong>
        <br />
        <br />
        This August, all soil test orders are included in our annual summer
        sale! Please make note of promo code
        {' '}
        <b>summersale24</b>
        {' '}
        to use during checkout.
        <br />
        <br />
        This 5% off seasonal promo is
        valid now through Saturday, August 31st.
        <br />
      </Alert>

      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item style={{ margin: '0 auto' }}>
                <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Nutrient Analysis Package
                </Typography>
                <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Starting at $49.95
                </Typography>
                <div className={classes.checkWrapper}>
                  Do you agree to our terms and conditions?
                  <Checkbox className={classes.confirmChecked} />
                </div>
                {
                  <div className={classes.buyButtonWrapper}>
                    <NutrientPricingDialog onBuyClick={handleBuy} pageLocation="Top" />
                  </div>
                }
                {
                  /*
                  <>
                    <Button
                      className={classes.buyButton}
                      variant="contained"
                      color="grey"
                    >
                      BUY NOW
                    </Button>
                    <br />
                    <br />
                    <div className={classes.orderDisable}>
                      Orders are currently unavailable. Please try again later.
                      <br />
                    </div>
                  </>
                  */
                }
              </Grid>
            </Grid>
          </div>
          <div className={(classes.section)}>
            <img
              alt="Soil analysis kit"
              height="286"
              width="420"
              className={classes.boxImg}
              src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
              loading="lazy"
            />
          </div>
        </div>
      </Container>

      <Container className={classes.blurbs} maxWidth="lg">
        <div className={classes.blurb}>
          <img
            alt="Flowers in laboratory glass"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/science.webp`}
            title="Fast"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Reliable Laboratory Soil Analysis
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Samples are processed using
            the Mehlich-3 lab method, displaying 13 different soil properties with clear, accurate
            results. Maintain optimal soil health and track your product applications year over
            year.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Money returning to yourself"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/save.webp`}
            title="Save"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Fast Free Shipping Included
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Fast free shipping, to get the kit to you, and to mail in your soil sample to the lab,
            giving you results and soil balancing instructions right from the comfort of your
            home in about a week.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Planet earth"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/earth.webp`}
            title="Environment"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Responsible Soil Management
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            Free recommendations tailored specifically to your soil, so you&apos;ll know
            exactly what your soil needs. You achieve balance without overusing fertilizers,
            saving you money and helping the environment.
          </Typography>
        </div>
      </Container>

      <Container className={classes.blurbs} maxWidth="lg">
        <div className={classes.blurb}>
          <img
            alt="Company logo"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/icon.webp`}
            title="Beaker"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            Approachable, Powerful Testing
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            With materials, lab fees, shipping, recommendations, and great service
            included in one price, we are consistently rated the easiest and most comprehensive
            way of having your soil tested.
          </Typography>
        </div>
        <div className={classes.blurb}>
          <img
            alt="Laboratory with scientists"
            height="120"
            width="120"
            src={`${process.env.PUBLIC_URL}/images/labIcon.webp`}
            title="Lab"
            loading="lazy"
          />
          <Typography variant="h6" component="h6" align="center" gutterBottom>
            No Program Signup Required
          </Typography>
          <Typography variant="subtitle2" component="p" align="center" paragraph>
            We don&apos;t sell fertilizers, just the testing. Our recommendations can be taken
            to any garden center or nursery of your choice. No expensive fertilizer programs or
            sign-ups required.
          </Typography>
        </div>
      </Container>

      {
      /*
      <Alert className={classes.mainAlert} severity="info">
        <strong>Father&apos;s Day Sale: </strong>
        <br />
        <br />
        We are currently running our Father&apos;s Day Sale between now and June 19th!
        For nutrient test orders, please use the promo code
        <b> fathersday2022 </b>
        at checkout for a 10% discount on your order.
        <br />
        <br />
        Gift orders placed by Monday June 13th will typically arrive before Sunday, June 19th.
      </Alert>
      <Alert className={classes.mainAlert} severity="info">
        <strong>June 2022: </strong>
        <br />
        Due to recent shortages in the global fertilizer market, we know you may have trouble
        finding amendment products. You can always email or call a member of our team to
        discuss your test results and alternative recommendations to suite your needs.
        <br />
        <br />
        <b>844-797-6454</b>
      </Alert>
      <Alert className={classes.volumeAlert} severity="warning">
        <strong>Please Note: </strong>
        <br />
        We are currently experiencing a high volume of orders. It may take up to 2 weeks
        ro receieve your kit.
        <br />
      </Alert>
      */
      }
      {
      /*
            <Alert className={classes.mainAlert} severity="info">
        This page contains information about our Nutrient Analysis Kit - if this is your first time
        testing your soil, you may also be interested in our Texture Analysis Kit. For more
        information about that test, and to buy the package that contains both, please see our
        <span> </span>
        <Link
          href="/texture"
          variant="subtitle1"
          color="textSecondary"
          className={classes.innerLink}
        >
          Texture Analysis Page
        </Link>
        .
      </Alert>
      */
      }

      <div className={classes.main}>
        <Typography variant="h4" align="center" gutterBottom>
          What&apos;s inside a Nutrient Analysis Kit?
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          Easy to understand instruction card:
        </Typography>
        <img
          alt="Four step instruction card for sampling soil and sending it to the lab"
          height="570"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/4x6instructionsFront_B2C.webp`}
          loading="lazy"
        />
        <br />
        <div className={classes.twoSections}>
          <div className={classes.mainSection}>
            <Typography variant="h5" align="center" paragraph>
              Compostable soil collection bag:
            </Typography>
            <img
              alt="Soil collection bag"
              height="400"
              width="345"
              className={classes.bagImg}
              src={`${process.env.PUBLIC_URL}/images/bag.webp`}
              loading="lazy"
            />
          </div>
          <div className={classes.mainSection}>
            <Typography variant="h5" align="center" paragraph>
              Pre-paid shipping label to our lab:
            </Typography>
            <img
              alt="Soil analysis kit with prepaid shipping label"
              height="340"
              width="495"
              className={classes.shipImg}
              src={`${process.env.PUBLIC_URL}/images/b2cBoxBack.webp`}
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div className={classes.analysis}>
        <Typography variant="h4" align="center" gutterBottom>
          Simple to Understand Results
        </Typography>
        <br />
        <br />
        <img
          alt="Soil analysis graph and chart showing optimal ranges"
          height="942"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/soil-analysis.webp`}
          loading="lazy"
        />
        <br />
      </div>

      <div className={classes.main}>
        <Typography variant="h4" align="center" gutterBottom>
          Advanced In-Depth Recommendations
        </Typography>
        <Container className={classes.secondCta} maxWidth="lg">
          <div className={classes.twoSections}>
            <div className={classes.sectionIconMob}>
              <img
                alt="Multi level grid with plot marks"
                height="200"
                width="200"
                src={`${process.env.PUBLIC_URL}/images/soilData.webp`}
                loading="lazy"
              />
            </div>
            <div className={classes.sectionText}>
              <Typography variant="h6" align="left" color="textPrimary" paragraph>
                By asking a few basic questions about your growing area, we include
                detailed recommendations for which products to put down, when, and how much.
              </Typography>
            </div>
            <div className={classes.sectionIconDesk}>
              <img
                alt="Multi level grid with plot marks"
                height="200"
                width="200"
                src={`${process.env.PUBLIC_URL}/images/soilData.webp`}
                loading="lazy"
              />
            </div>
          </div>
        </Container>
        <img
          alt="Soil amendment recommendation chart showing instructions"
          height="1280"
          width="840"
          className={classes.instructionsImg}
          src={`${process.env.PUBLIC_URL}/images/recommendations.webp`}
          loading="lazy"
        />
      </div>

      <div className={classes.bottomCTA}>
        <Typography variant="h5" align="center" gutterBottom>
          Ready to get started?
          <br />
          <br />
          Join the tens of thousands already testing with Rx Soil
          today!
          <br />
          <br />
          Purchase a soil test kit or contact us with any questions.
        </Typography>
      </div>
      <br />
      <br />

      {
      /*
      <Alert className={classes.volumeAlert} severity="warning">
        <strong>Please Note: </strong>
        <br />
        We are currently experiencing a high volume of orders. It may take up to 2 weeks
        ro receieve your kit.
        <br />
      </Alert>
      */
      }

      <Container className={classes.secondCta} maxWidth="lg">
        <div className={classes.twoSections}>
          <div className={classes.section}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item style={{ margin: '0 auto' }}>
                <Typography className={classes.productName} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Nutrient Analysis Package
                </Typography>
                <Typography className={classes.productPrice} variant="h4" align="left" color="textPrimary" gutterBottom>
                  Starting at $49.95
                </Typography>
                <div className={classes.checkWrapper}>
                  Do you agree to our terms and conditions?
                  <Checkbox className={classes.confirmChecked} />
                </div>
                {
                  <div className={classes.buyButtonWrapper}>
                    <NutrientPricingDialog onBuyClick={handleBuy} pageLocation="Bottom" />
                  </div>
                }
                {
                  /*
                  <>
                    <Button
                      className={classes.buyButton}
                      variant="contained"
                      color="grey"
                    >
                      BUY NOW
                    </Button>
                    <br />
                    <br />
                    <div className={classes.orderDisable}>
                      Orders are currently unavailable. Please try again later.
                      <br />
                    </div>
                  </>
                  */
                }
              </Grid>
            </Grid>
          </div>
          <div className={(classes.section)}>
            <img
              alt="Soil analysis kit"
              height="286"
              width="420"
              className={classes.boxImg}
              src={`${process.env.PUBLIC_URL}/images/b2cBoxFront.webp`}
              loading="lazy"
            />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Nutrients;
