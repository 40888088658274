// React Redux Core
import React, { useState } from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Snackbar from '@material-ui/core/Snackbar';
import { Typography, IconButton, Collapse } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '0px 0px',
    margin: '0px',
  },
  container: {
    maxWidth: 400,
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  headerText: {
    flex: '1 1 auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTextContainer: {
    flex: '1 1 calc(100% - 40px)', // Adjust this value based on the size of the icon + some margin
    paddingRight: theme.spacing(2),
  },
  headerIcon: {
    flex: '0 0 40px', // This reserves space for the icon, adjust based on icon's size
    textAlign: 'right',
  },
  slider: {
    marginTop: theme.spacing(2),
    // ... Other styles for your slider if any
    '& .MuiSlider-valueLabel': {
      fontSize: '1.5rem',
    },
    '& .MuiSlider-valueLabel>span': {
      background: 'transparent',
      fontFamily: 'inherit',
      height: 'auto',
      width: 'auto',
      borderRadius: '60%', // make it rounded
      transform: 'scale(1) translateX(2px) translateY(4px)', // reset the scale
    },
    '& .MuiSlider-valueLabel>span>span': {
      transform: 'none',
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      borderRadius: '50%', // make it rounded
      padding: '0px 10px', // Add some padding to give room for the value

      // The following styles are for the arrow (teardrop appearance)
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '0',
        height: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: '-6px',
        borderLeft: '7px solid transparent',
        borderRight: '7px solid transparent',
        borderTop: `8px solid ${theme.palette.primary.main}`,
      },
    },
  },
  priceBreakdown: {
    marginTop: theme.spacing(1),
  },
  buyButton: {
    fontWeight: 600,
  },
  alertStyle: {
    alignSelf: 'center',
    width: '95%',
    marginTop: '4%',
    fontSize: 18,
    marginBottom: '0%',
    [theme.breakpoints.down(1174)]: {
      width: '95%',
    },
  },
}));

const NutrientPricingDialog = ({ onBuyClick, pageLocation }) => {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(1);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showSavedAmount, setShowSavedAmount] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const prices = {
    1: 49.95,
    2: 42.50,
    3: 40.00,
    4: 36.25,
    5: 34.00,
  };

  const handleSliderChange = (event, newValue) => {
    if (newValue > 5) {
      setOpenSnackbar(true);
      return;
    }
    if (newValue !== 1) {
      setShowSavedAmount(true);
    }
    if (newValue === 1) {
      setShowSavedAmount(false);
    }
    setQuantity(newValue);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className={classes.buyButton}
        variant="contained"
        color="primary"
        tabIndex="0"
        onClick={handleClickOpen}
        onKeyDown={(event) => {
        // For keyboard enter events
          if (event.keyCode === 13 || event.keyCode === 32) {
            handleClickOpen();
          }
        }}
      >
        BUILD YOUR PACKAGE
      </Button>

      <Dialog open={open} onClose={handleClose} scroll="paper" className={classes.dialog} hideBackdrop>
        <Alert className={classes.alertStyle} severity="info" icon={false}>
          <div className={classes.header}>
            <div className={classes.headerTextContainer}>
              <Typography style={{ fontSize: 19, fontWeight: 500 }}>
                How Many Samples Should I Order?
              </Typography>
            </div>
            <div className={classes.headerIcon}>
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                size="small"
                className={`${classes.expand} ${expanded ? classes.expandOpen : ''}`}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </div>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Typography paragraph>
              Ordering a single nutrient analysis sample is recommended if
              you have a smaller growing plot and plant growth is similar across the whole area.
            </Typography>
            <Typography paragraph>
              Consider ordering additional samples if you want to compare different soil
              reports across your property.
            </Typography>
          </Collapse>
        </Alert>
        <DialogContent>
          <div className={classes.container}>
            <Typography variant="h5">Number of Samples</Typography>
            <br />
            <Slider
              className={classes.slider}
              defaultValue={1}
              step={1}
              marks
              min={1}
              max={5}
              valueLabelDisplay="on"
              onChange={handleSliderChange}
            />
            <div className={classes.priceBreakdown}>
              <Typography variant="h6">
                $
                {(prices[quantity]).toFixed(2)}
                &nbsp;per sample
              </Typography>
              <br />
              <Typography variant="h6">
                Total: $
                {(prices[quantity] * quantity).toFixed(2)}
              </Typography>
              <div style={{ height: '20px' }}>
                {showSavedAmount && (
                <Typography color="secondary">
                  You saved: $
                  {((49.95 - prices[quantity]) * quantity).toFixed(2)}
                </Typography>
                )}
              </div>
            </div>
            <br />
            <Button variant="contained" color="primary" disabled={quantity > 5} onClick={() => onBuyClick(pageLocation, quantity)}>
              Add to Cart
            </Button>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message="For bulk orders, please contact us!"
              action={(
                <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                  Close
                </Button>
      )}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NutrientPricingDialog;
