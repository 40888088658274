import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const BlogPosts = ({ posts }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(Array(posts.length).fill(false));

  const handleExpandClick = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  return (
    <div>
      {posts.map((post, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Card key={index} className={classes.card}>
          <CardHeader
            title={post.title}
            subheader={`${post.date}`}
            action={(
              <IconButton
                className={`${classes.expand} ${
                  expanded[index] ? classes.expandOpen : ''
                }`}
                onClick={() => handleExpandClick(index)}
                aria-expanded={expanded[index]}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          />
          <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography style={{ whiteSpace: 'pre-line' }} variant="body1" color="textSecondary" component="p">
                {post.content}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  );
};

export default BlogPosts;
