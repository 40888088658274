// React Redux Core
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Adapify
import Website from './Website';
import Canna from './Website/Canna';
import Contact from './Website/Contact';
import Support from './Website/Support';
import PLFA from './Website/PLFA';
import Metals from './Website/Metals';
import Pesticides from './Website/Pesticides';
import Nutrients from './Website/Nutrients';
import Packages from './Website/Packages';
import Texture from './Website/Texture';
import Compost from './Website/Compost';
import BlogDriver from './Website/BlogDriver';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/support" component={Support} />
      <Route path="/contact" component={Contact} />
      <Route path="/texture" component={Texture} />
      <Route path="/compost" component={Compost} />
      <Route path="/microorganisms" component={PLFA} />
      <Route path="/metals" component={Metals} />
      <Route path="/pesticides" component={Pesticides} />
      <Route path="/nutrients" component={Nutrients} />
      <Route path="/packages" component={Packages} />
      <Route path="/blog" component={BlogDriver} />
      <Route
        path="/partner"
        component={() => {
          window.location.href = 'https://partner.rxsoil.com';
          return null;
        }}
      />
      <Route
        path="/app"
        component={() => {
          window.location.href = 'https://app.rxsoil.com';
          return null;
        }}
      />
      <Route
        path="/register"
        component={() => {
          window.location.href = 'https://app.rxsoil.com/register';
          return null;
        }}
      />
      <Route path="/canna" component={Canna} />
      <Route path="/" component={Website} />
    </Switch>
  </BrowserRouter>
);

export default App;
